import { Link } from "@reach/router";
import React from "react";
import Notification from "../components/page/Notification";
import { OptOutInfoNotification } from "../components/page/OptOutInfoNotification";
import LoginForm from "../components/user-management/LoginForm";

type LoginPagePropType = {
  location: any; // TODO: Das sollte nicht "any" sein.
};

const LoginPage = ({ location }: LoginPagePropType) => {
  const referralUrl = location.state ? location.state.referralUrl : null;
  const referralMessage = location.state ? location.state.referralMessage : null;
  const referralMessageOptOutKey = location.state ? location.state.referralMessageOptOutKey : null;

  return (
    <div>
      {referralMessage && !referralMessageOptOutKey ? <Notification type="info">{referralMessage}</Notification> : null}
      {referralMessage && referralMessageOptOutKey ? (
        <OptOutInfoNotification optOutKey={referralMessageOptOutKey}>{referralMessage}</OptOutInfoNotification>
      ) : null}
      <h2>Login</h2>
      <div style={{ maxWidth: "400px", margin: "auto" }}>
        <LoginForm targetOnLogin={referralUrl} />
        <div className="text-center">
          Noch keinen Account? <Link to="/register">Hier kostenlos anmelden</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
