import React, { useContext } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";

import { authenticationService } from "../../_services";
import { Context } from "../store";
import { addError } from "../../utils/errors";
import WaitingAnimation from "../WaitingAnimation";
import { navigate } from "gatsby-link";
import TextFormikField from "../FormikFields/TextFormikField";
import _ from "lodash";

type LoginFormPropType = {
  targetOnLogin: string;
};

const LoginForm = ({ targetOnLogin }: LoginFormPropType) => {
  const [, setState] = useContext(Context);

  const LoginSchema = yup.object().shape({
    username: yup.string().email("Invalid email").required("Bitte geben Sie Ihre Email an"),
    password: yup.string().required("Bitte geben Sie ein Passwort ein"),
  });

  return (
    <div className="w-full block">
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginSchema}
        onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
          username = username.toLowerCase(); // Only lowercase is valid.
          setStatus();
          authenticationService
            .login(username, password)
            .then((res) => {
              navigate(targetOnLogin || "/");
            })
            .catch((err) => {
              addError(setState, err.message);
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="username">Email</label>
              <Field type="email" component={TextFormikField} className="w-full" name="username" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field component={TextFormikField} className="w-full" name="password" type="password" />
            </div>
            <div className="form-group text-right">
              {isSubmitting ? <WaitingAnimation /> : null}
              <div>
                <button className="button button-primary" type="submit" disabled={isSubmitting || _.some(errors)}>
                  Login
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
